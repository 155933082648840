import React, { useMemo, useState } from "react";
import "./index.css";
import logo from "./images/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faBackspace,
  faCheckCircle,
  faCheckSquare,
  faCoffee,
  faEnvelope,
  faEye,
  faPlus,
  faPlusCircle,
  faSlidersH,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import user from "./images/user.png";

import "./App.css";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../node_modules/startbootstrap-simple-sidebar/css/simple-sidebar.css";

import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import "jquery-bar-rating/dist/themes/css-stars.css";

import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";

import "pretty-checkbox/dist/pretty-checkbox.css";
import { json } from "./survey_json.js";

import ControlledLottie from "./lotties/ControlledLottie";
import VideoCap from "./VideoCapture.js";
import Speech from "speak-tts";

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);

// function onValueChanged(result) {
//     console.log("value changed!");
// }

// function onComplete(result) {
//     console.log("Complete! " + result);
// }

const SurveyList = () => {
  const [refresh, setRefrsh] = useState(true);
  const [count, setCount] = useState(0);
  const model = new Survey.Model(json);
  const speech = new Speech();

  useMemo(() => {
    if (refresh) {
      speech
        .speak({
          text: "Survey Personal Info",
        })
        .then(() => {
          setRefrsh(true);
          console.log("Success !");
        })
        .catch((e) => {
          setRefrsh(true);
          console.error("An error occurred :", e);
        });
    }
  }, [refresh]);
  const onValueChanged = (result) => {
    console.log("value changed!");
  };

  const onComplete = (result) => {
    console.log("Complete! " + result);
  };
  const onPartialSend = (res) => {
    console.log(res);
  };
  return (
    <div>
      <Navbar
        class="shadow p-3 mb-5 bg-white rounded"
        style={{ boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)" }}
      >
        <div class="container-fluid">
          <Navbar.Brand href="#home">
            <Image src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"></Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div className="container py-5 body-wrapper">
        <div class="form-wrapper">
          <Survey.Survey
            model={model}
            onComplete={onComplete}
            onValueChanged={onValueChanged}
          />
          <div class="right-img-container">
            {/* <div class ="webcam-img col-img"><button>Capture</button></div> */}
            <div class="webcam-close-icon col-img">
              <VideoCap />
            </div>
            <div class="animated-img col-img">
              {" "}
              {/*<Image style={{ height:100, }} src={'https://www.geniuscr8.com/wp-content/uploads/2018/07/Genius-Concept-Sample-Logo-2.jpg'} />*/}{" "}
              <ControlledLottie />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SurveyList;
