import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../lotties/robot.json'

class ControlledLottie extends Component {
  state = {isStopped: false, isPaused: false}

  state = {
    minutes: 0,
    seconds: 10
  }


  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1
        }))
      }
      if (seconds === 0) {
        this.setState({ isStopped: true })
        if (minutes === 0) {
          clearInterval(this.myInterval)
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59
          }))
        }
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  render(){

    const { minutes, seconds } = this.state
    const buttonStyle = {
      display: 'inline-block',
      margin: '10px auto',
      marginRight: '10px',
      border: 'none',
      color: 'white',
      backgroundColor: '#647DFF',
      borderRadius: '2px',
      fontSize: '15px'

    };

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="controlled">
        <Lottie options={defaultOptions}
              height={100}
              width={100}
               isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        
      </div>
    )
  }
}

export default ControlledLottie