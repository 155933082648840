import React from "react";
import "../../index.css";
import logo from "../../images/logo.png";

import "../../App.css";
import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../../../node_modules/startbootstrap-simple-sidebar/css/simple-sidebar.css";

import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import "jquery-bar-rating/dist/themes/css-stars.css";

import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";

import "pretty-checkbox/dist/pretty-checkbox.css";
import { json } from "../JsonFiles/screener_json";

import ControlledLottie from "../../lotties/ControlledLottie";
import VideoCap from "../../VideoCapture";

import Axios from "axios";
import { BottomScrollListener } from "react-bottom-scroll-listener";

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);

function onValueChanged(result) {
  console.log("value changed!");
}
function onComplete(result) {
  console.log("Complete! ");
  console.log(result.data);
}

const model = new Survey.Model(json);

class SurveyScreener extends React.Component {
  state = {
    isNdaSigned: false,
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    let data = {};

    for (let params of query.entries()) {
      data[params[0]] = +params[1];
      this.setState({ isNdaSigned: true });
    }

    console.log(data.env);
    console.log(data.pf);
    console.log(data.r);

    //  this.setState({ urldata: data});
  }
  handleOnDocumentBottom = () => {
    console.log("I am at bottom! " + Math.round(performance.now()));
  };
  render() {
    return (
      <div>
        {/* When you only want to listen to the bottom of "document", you can put it anywhere */}
        <BottomScrollListener onBottom={this.handleOnDocumentBottom} />

        <Navbar
          class="shadow p-3 mb-5 bg-white rounded"
          style={{ boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)" }}
        >
          <div class="container-fluid">
            <Navbar.Brand href="#home">
              <Image src={logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto"></Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>

        <div className="container py-5 body-wrapper">
          {this.state.isNdaSigned && (
            <div class="form-wrapper survey-screener">
              <Survey.Survey
                model={model}
                onComplete={onComplete}
                onValueChanged={onValueChanged}
              />
              <div class="right-img-container ">
                {/* <div class ="webcam-img col-img"><button>Capture</button></div> */}
                <div class="webcam-close-icon col-img">
                  <VideoCap />
                </div>
                <div class="animated-img col-img">
                  {" "}
                  <ControlledLottie />
                </div>
                <div class="animated-img col-img">
                  {" "}
                  <ControlledLottie />
                </div>
              </div>
            </div>
          )}

          {!this.state.isNdaSigned && (
            <div class="nda-btn-wrapper">
              <div class="nda-heading">
                To initiate this survey please sign "Non Disclosure Agreement"
              </div>
              <a
                class="login-txt"
                href="https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=7fda8997-d965-4800-9955-c152b632359c&env=demo&acct=882d45ba-b2f0-4191-96e4-e80a090d0fd2&v=2"
              >
                Let me sign it
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SurveyScreener;
